.top-nav-container {
  position: relative;
  width: 100%;
  background-color: #107636;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-group {
  display: flex;
  gap: 1rem;
}

.contact-info {
  margin-right: 2rem;
}

@media only screen and (max-width: 1240px) {
  .top-nav-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #107636;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .icon-group {
    display: flex;
    gap: 0.6rem;
  }
  
  .contact-info {
    margin-right: 0.2rem;
  }
}
