@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
	text-align: left;
}

:root {
	--mainColor: #107636; /* Change to green color */
	--secondaryColor: #107636;
	--textColor: #; /* Change to white color */
}

.navbar-container {
    position: relative;
}

.sticky-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
      transition: top 0.3s ease; /* You can adjust the duration and easing function */
  /* Add any other styles you want for the sticky navbar */
}

.sindhi-container {
    position: relative;
    z-index: 1;
}

.sindhi {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

header {
    position: absolute;
    top: 7.5%;
    left: 0;
    width: 100%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.9); /* Change to green color with alpha */
    color: var(--textColor);
    z-index: 20;
    font-weight: bold; /* Make the text bold */
}

.logo-image {
    padding: 1rem;
    width: 250px;
    max-width: 100%;
    height: auto;
}

nav {
    display: flex;
    align-items: center;
    margin-left: auto;
}

nav a,
nav button {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: none;
  background-color: transparent;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold; /* Make the text bold */
}

nav a:hover,
nav button:hover {
  background-color: var(--secondaryColor); /* Change to secondary color on hover */
}

header .nav-btn,
header .nav-btnbar {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

/* Modal */
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
  }
  
  .modal-content {
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .modal-close-button {
	margin-top: 10px;
  }



@media only screen and (max-width: 1240px) {
  .logo-image {
    width: 210px;
  }

  header .nav-btn,
  header .nav-btnbar {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
	
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }

  /* Adjust the sindhi-container for small screens */
  .sindhi-container {
    display: block; /* Hide the sindhi-container */
	height: 13vh; /* Increase the height for a larger size */
  }

  nav button {
    color: white;
  }
}

